<template>
  <div class="classify-list">
    <div class="c-list" v-for="(item, index) in FloorList" :key="index">
      <div class="c-title">
        <div class="t-name">{{ item.name }}</div>
        <div class="t-more">
          <a
            :href="'/store/all/list?storeid=' + StoreId + '&id=' + item.id"
            target="_blank"
          >
            查看更多
            <i class="fa fa-angle-right fa-lg"></i>
          </a>
        </div>
      </div>
      <div class="c-image">
        <a :href="item.pcurl" target="_blank">
          <img :src="item.pcadvimg" />
        </a>
      </div>
      <!-- 商品展示 1-->
      <ul class="c-goods">
        <li
          class="g-item"
          v-for="(vala, keya) in item.goodslist"
          :key="keya"
          @click="togoods(vala.id)"
        >
          <div class="picture">
            <img :src="vala.img" />
          </div>
          <div class="i-name">
            <a>{{ vala.goods_name }}</a>
          </div>
          <div class="price">
            <div class="p-color">¥{{ vala.price }}</div>
            <div class="p-sales">
              成交量: <span>{{ vala.vbep }}笔</span>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "classifyList",
  data() {
    return {
      foor:[]
    }
  },
  props: {
    FloorList: {
      type: Array 
    },
    StoreId: {
      type: Number 
    }
  },
  methods: {
    togoods(id) {
      let routeUrl = this.$router.resolve({
        path: "/store/goods",
        query: { id: id }
      });
      window.open(routeUrl.href, '_blank');
    },
  },
}
</script>

<style lang="less" scoped>
.classify-list {
  width: 1200px;
  margin: 0 auto;
  min-height: 1000px;
  padding-top: 20px;
  //   border: 1px solid #e1251b;
  .c-list {
    padding-bottom: 20px;
    .c-title {
      height: 36px;
      line-height: 36px;
      display: flex;
      justify-content: space-between;
      .t-name {
        font-size: 18px;
        font-weight: bold;
        color: #333;
      }

      .t-more {
        line-height: 36px;
        font-size: 12px;
        cursor: pointer;
        text-align: center;
        font-size: 14px;
        margin-right: 10px;
        i {
          vertical-align: -5%;
        }
        &:hover {
          a {
            color: #e1251b;
          }
          border-bottom: 2px solid #e1251b;
        }
      }
    }
    .c-image {
      height: 350px;
      margin: 10px 0px;
      a {
        img {
          width: 1200px;
          height: 350px;
        }
      }
    }
    .c-goods {
      display: flex;
      justify-content: space-between;
      .g-item {
        width: 295px;
        height: 350px;
        box-sizing: border-box;
        background: #fff;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;
        transition: box-shadow 0.5s;
        &:hover {
          box-shadow: 2px 5px 10px gray;
        }

        .picture {
          width: 270px;
          height: 230px;

          img {
            width: 100%;
            height: 100%;
          }
        }
        .price {
          width: 100%;
          display: flex;
          justify-content: space-between;
          .p-color {
            font-size: 22px;
            font-weight: bold;
            color: #e1251b;
          }
          .p-sales {
            line-height: 30px;
            span {
              color: #e1251b;
            }
          }
        }
        .i-name {
          width: 100%;
          height: 40px;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          color: #333;
          font-size: 14px;
        }
      }
    }
  }
}
</style>