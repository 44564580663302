<template>
  <div class="store-home">
    <Search></Search>
    <div class="s-container" v-loading="loading">
      <!-- 导航栏组件 -->
      <tabNav :store_id="id"></tabNav>
      <!-- 轮播图 -->
      <components :is="storeSwiper" :swiperImages="swiper"></components>
      <!-- 商品列表 -->
      <goods-classify :FloorList="FloorList" :StoreId="id"></goods-classify>
    </div>
  </div>
</template>

<script>
  import Search from '../../components/search.vue'
  import tabNav from '../../components/tabNav.vue'
  import goodsClassify from './goodsClassify.vue'
  import { StoreInitial } from '@/api/index'
  export default {
    name: "storeHome",
    components: {
      goodsClassify,
      tabNav,
      Search
    },
    data() {
      return {
        id: '',
        FloorList: [], // 楼层数据
        swiper: [], // 轮播图数据
        storeSwiper: ''
      }
    },
    methods: {
      async getInfo() {
        this.loading = true
        const { data: res } = await StoreInitial({ id: this.id });
        if (res.message == "ok") {
          this.swiper = res.result.swiper;
          this.FloorList = res.result.floorlist;
          this.$nextTick(() => {
            this.storeSwiper = () => import('./storeSwiper.vue')
          })
        } else {
          this.$router.push("/home/error?mes=该门店不存在");
          return false;
        }
        this.loading = false
      }
    },
    created() {
      this.id = this.$route.query.id;
      if (typeof this.id !== 'number' && isNaN(this.id)) { this.$router.push("/home/error"); return false; }
      this.id = parseInt(this.id);
      this.getInfo();
    }
  }
</script>

<style lang="less" scoped>
  .store-home {
    min-height: 1000px;
    background: #f5f6f7;
  }
</style>